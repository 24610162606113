import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import { HomeContext as DataContext } from "/componentry/Root/Shared/DataContexts.jsx";
import { useContext } from "react";

import Link from "next/link";
import SizeDependentCarousel from '/componentry/Home/Carousels/Clients/SizeDependentCarousel';

export default function CompanyCarouselCore({ props }) {
    const { dataContext } = useContext(DataContext);
    const { loaded, bigClients } = dataContext;

    return (
        <div>
            <div>
                <div className="flex flex-row items-center justify- gap-x-4 gap-y-4 pb-6">
                    <h1 className="text-2xl md:text-3xl text-left">Clients</h1>
                    <Link href="/clients" className="flex flex-row font-BreezeButton gap-x-2 text-xs leading-tight px-4 py-2 bg-cyan-200 hover:bg-cyan-300 active:shadow-lg transition duration-150 ease-in-out uppercase text-sp text-black rounded-full">
                        More
                    </Link>
                </div>
            </div>
            <div>
                <SizeDependentCarousel dependents={{ loaded, clients: bigClients }} breakpoint="mobile" slidesPerView={2.5} />
            </div>
            <SizeDependentCarousel dependents={{ loaded, clients: bigClients }} breakpoint="sm" slidesPerView={4} />
            <SizeDependentCarousel dependents={{ loaded, clients: bigClients }} breakpoint="md" slidesPerView={3.5} />
            <SizeDependentCarousel dependents={{ loaded, clients: bigClients }} breakpoint="lg" slidesPerView={5} />
            <SizeDependentCarousel dependents={{ loaded, clients: bigClients }} breakpoint="xl" slidesPerView={6} />
            <SizeDependentCarousel dependents={{ loaded, clients: bigClients }} breakpoint="2xl" slidesPerView={8} />
        </div>
    );  
};