import { useRouter } from "next/router";
import Image from "next/image";

export default function Section(props) {
  const router = useRouter();
  return (
    <div className={`${props.alternate ? "bg-gray-700" : "bg-transparent"} px-12 md:px-10 lg:px-24 xl:px-48 py-14 md:py-28`}>
        <div className={`flex ${props.alternate ? "justify-evenly flex-col gap-y-4 md:gap-y-0 md:flex-row-reverse" : "justify-evenly flex-col gap-y-4 md:gap-y-0 md:flex-row"} items-center`}>
            { props.iconSrc ? <Image src={props.iconSrc} width="1024" height="1024" alt={props.alt} className="pb-6 md:pb-0lg:mx-32 w-56 md:w-48 lg:w-56 xl:w-72 2xl:w-96" /> : null }
            <div className={`md:text-${props.alternate ? "left" : "right"} flex flex-col space-y-4`}>
                <h1 className={`md:text-${props.alternate ? "left" : "right"} font-bold font-BreezeHeader text-2xl md:text-4xl max-w-[650px]`}>{props.header}</h1>
                <p className={`md:text-${props.alternate ? "left" : "right"} font-medium font-BreezeText text-lg max-w-[650px]`}>
                    {props.description}
                </p>
            </div>
        </div>
    </div>
  );
};