import { useState } from "react";

const components = {
    Base: 60,
    FootagePer5: 10,
    SubtitlesPer1: 15,
    Music: 40,
    Effects: 50
}

export default function PriceEstimator() {
    const [BaseComponent, setBaseComponent] = useState(components.Base);
    const [FootagePer5, setFootagePer5] = useState(components.FootagePer5);
    const [SubtitlesPer1, setSubtitlesPer1] = useState(components.SubtitlesPer1);
    const [Music, setMusic] = useState(components.Music);
    const [Effects, setEffects] = useState(components.Effects);

    return (
        <div className="flex flex-col gap-y-4">
            
        </div>
    )
};