import { FaMicrosoft, FaPlaystation, FaXbox, FaTwitch, FaYoutube } from 'react-icons/fa';
import { SiMojangstudios } from "react-icons/si";

const companies = {
    Microsoft: {
        name: 'Microsoft',
        href: 'https://www.microsoft.com/en-us/',
        logo: FaMicrosoft,
        rounded: true
    },
    Mojang: {
        name: 'Mojang',
        href: 'https://www.mojang.com/',
        logo: SiMojangstudios,
    },
    PlayStation: {
        name: 'PlayStation',
        href: 'https://www.playstation.com/en-us/',
        logo: FaPlaystation
    },
    Xbox: {
        name: 'Xbox',
        href: 'https://www.xbox.com/en-US/',
        logo: FaXbox
    },
    Twitch: {
        name: 'Twitch',
        href: 'https://www.twitch.tv/',
        logo: FaTwitch
    },
    YouTube: {
        name: 'YouTube',
        href: 'https://www.youtube.com/',
        logo: FaYoutube
    }
};

export default companies;