import { FaQuestionCircle } from "react-icons/fa";


export default function Element(props) {
    const Icon = props.icon || FaQuestionCircle;
    return (
        <div className="flex flex-col space-y-4 text-xl items-center text-center">
            <div className="p-6 bg-cyan-800 rounded-full">
                <Icon className="text-white w-10 h-full text-4xl" />
            </div>
            <h1>{props.title}</h1>
            <p className="text-base font-medium">{props.children}</p>
        </div>
    );
}