import { useEffect, useState } from "react";
import TypingEffect from 'react-typing-effect';

let Strings = {
    editors: {
        main: "editors.",
        about: "an editor"
    },
    graphicDesigners: {
        main: "graphic designers.",
        about: "a graphic designer"
    },
    managers: {
        main: "managers.",
        about: "a manager"
    },
    developers: {
        main: "developers.",
        about: "a developer"
    },
    graphicArtists: {
        main: "graphic artists.",
        about: "a graphic artist"
    },
    thumbnailArtists: {
        main: "thumbnail artists.",
        about: "a thumbnail artist"
    },
    artists: {
        main: "artists.",
        about: "an artist"
    },
    animators: {
        main: "animators.",
        about: "an animator"
    },
};

export { Strings };

export default function Headline() {
    const [currentText, setCurrentText] = useState(0);
    
    let mainStrings = [];

    Object.keys(Strings).forEach(function(key, index) {
        mainStrings.push(Strings[key].main);
    });
    
    useEffect(() => {
        let interval = setInterval(() => {
            if (currentText === mainStrings.length - 1) setCurrentText(0);
            else setCurrentText(currentText + 1);
        }, (1000 * 2.5));

        return () => clearInterval(interval);
    }, [currentText]);

    return (
        <p className="text-xl font-BreezeText font-medium text-left sm:text-left whitespace-normal pt-1 pb-4">
            I provide content creators with <br />
            {
                mainStrings.map((string, index) => {
                    return (
                        <b key={index} className={`${currentText === index ? "transition-all inline-block animate-fadeDown" : "animate-fadeUp opacity-0"} absolute overflow-hidden font-medium`}><b>{string}</b></b>
                    );
                })
            }
        </p>
    );
};