"use client";
import factory from "tiny-relative-date/lib/factory";
import * as fns from "duration-fns";
import { formatTime } from "/lib/mathsExtension.js";
import { playlistIds, trdLocale, blurImageURL } from "/lib/publicEnv.js";

import { useState } from 'react';

import Image from 'next/image';
import { FaCrown, FaPencilAlt, FaStar } from 'react-icons/fa';

export default function Thumbnail(props) {
    let videoType = "Kooleyy";
    if (props.sort?.editor === "team") {
        videoType = "Team";
    };
    if (props.sort?.favourite === true) {
        videoType = "Best";
    };

    let modTranslate = trdLocale;
    modTranslate.overAYearAgo = `${new Date().getFullYear() - new Date(props.timestamp).getFullYear()} years ago`;

    const relativeDate = factory(modTranslate);

    const [videoSrc, setVideoSrc] = useState(props.thumb || "/thumbnail.jpg");

    const duration = formatTime(fns.toSeconds(props.duration));
    let rd = relativeDate(props.timestamp);

    return (
        <div className="flex flex-col gap-y-3">
            <a href={props.href} rel="noreferrer" target="_blank" className="relative w-auto rounded-2xl overflow-hidden">
                <div className="bg-black opacity-0 hover:opacity-[0.15] absolute w-full h-full top-0 left-0 transition-all ease-in-out duration-200" />
                <Image src={videoSrc} placeholder="blur" blurDataURL={blurImageURL.video} width="348" height="196" alt={props.title} className={`object-cover w-full h-full bg-gray-500 text-gray-500`} style={{ backgroundPosition: "center" }} unoptimized={props.optimized ? false : true}
                    onLoadingComplete={(i) => {
                        if (i && i.naturalHeight == 90 && i.naturalWidth == 120) {
                            setVideoSrc("/thumbnail.jpg");
                        };
                    }}
                />
                {
                    (() => {
                        if (videoType === "Best") {
                            return (
                                <span className="absolute items-center w-fit mx-2 my-2 py-1 px-1 top-0 right-0 bg-gray-800 text-white text-xs text-center leading-4 rounded-md z-20">
                                    <FaStar className="text-yellow-300 w-5" />
                                </span>
                            );
                        } else {
                            return (
                                <span className="hidden items-center absolute w-fit mx-2 my-2 py-1 px-1 top-0 left-0 bg-gray-800 text-white text-xs text-center leading-4 rounded-md z-20">
                                    <FaPencilAlt className="pl-0.5 pr-2 w-6" />
                                    {videoType === "Team" ? "Continuity" : videoType === "Kooleyy"}
                                </span>
                            );
                        };
                    })()
                }
                <span className="absolute w-fit mx-2 my-2 py-1 px-1 bottom-0 right-0 bg-gray-800 text-white text-xs text-center leading-4 rounded-md z-20">{duration}</span>
                {props.timestamp ? (
                    <span className="absolute w-fit mx-2 my-2 py-1 px-1 bottom-0 left-0 bg-gray-800 text-white text-xs text-center leading-4 rounded-md z-20">
                        {rd}
                    </span>
                ) : null}
            </a>
                <div>
                    <a href={props.authorHref} target="_blank" rel="noreferrer noopener">
                        <Image height="64" width="64" referrerPolicy="no-referrer" className="rounded-xl w-12 sm:w-8 md:w-9 lg:w-10 xl:w-11 2xl:w-12 float-left mr-2 hover:opacity-[85%] transition-all ease-in-out duration-200" alt={props.author} src={props.authorPhoto || "/unloaded_client.png"} />
                    </a>
                    <div className="flex flex-col gap-y-2">
                        <div className="flex flex-row gap-x-2 items-start">
                            <span className="font-BreezeAltHeader font-medium text-sm flex flex-col">
                                <a href={props.href} rel="noreferrer" target="_blank" className={`font-BreezeAltHeader font-bold hover:text-gray-300 transition-all ease-in-out duration-200`}>{props.title}</a>
                                <div className="flex flex-row gap-x-1 font-semibold">
                                    <a href={props.authorHref} target="_blank" rel="noreferrer noopener"> 
                                        <span className="flex gap-x-1 items-center hover:text-gray-300 transition-all ease-in-out duration-200">     
                                            {
                                                props.authorId === "UCxlyTwuE1uuieLnl_tjBYVQ" ? 
                                                <>Kooleyy <FaCrown className="text-yellow-400 w-5" /></> 
                                                : props.author
                                            }
                                        </span>
                                    </a>{props.views ? ` · ${Intl.NumberFormat("en-US", { notation: "compact", maximumFractionDigits: 1 }).format(props.views)} views` : null}
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
        </div>
    );
};