import 'swiper/css';
import 'swiper/css/autoplay';

import companies from '/lib/companies.js';
import SizeDependentCarousel from '/componentry/Home/Carousels/Companies/SizeDependentCarousel';


export default function CompanyCarouselCore() {
    return (
        <div>
            <SizeDependentCarousel dependents={{ companies }} breakpoint="mobile" slidesPerView={3} />
            <SizeDependentCarousel dependents={{ companies }} breakpoint="md" slidesPerView={4} />
            <SizeDependentCarousel dependents={{ companies }} breakpoint="lg" slidesPerView={5} />
        </div>
    );  
};