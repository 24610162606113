/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './app/**/*.{js,ts,jsx,tsx,mdx}',
    './componentry/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    extend: {
      fontFamily: {
        "BreezeHeader": ["Quicksand", "Inter", 'ui-sans-serif', 'system-ui'],
        "BreezeButton": ["Inter", 'ui-sans-serif', 'system-ui',],
        "BreezeText": ["Inter", 'ui-sans-serif', 'system-ui',],
        "BreezeMono": ["Space Mono", "ui-monospace"],
        "DefaultMono": ["ui-monospace", "monospace"],
        "BreezeAltHeader": ["Quicksand", "Inter", 'ui-sans-serif', 'system-ui']
      },
      colors: {
        "kooleyy-bottomslate": "#101c37"
      },
      keyframes: {
        fadeUp: {
          "0%": {
            transform: "translateY(0%)",
            opacity: "1"
          },
          "40%": {
            opacity: "0"
          },
          "50%": {
            opacity: "0"
          },
          "100%": {
            transform: "translateY(100%)",
          }
        },
        fadeDown: {
          "0%": {
            transform: "translateY(-100%)",
            opacity: "0"
          },
          "40%": {
            opacity: "0"
          },
          "50%": {
            opacity: "1"
          },
          "100%": {
            transform: "translateY(0%)",
          }
        },
        wave: {
            '0%': { transform: 'rotate(0.0deg)' },
            '10%': { transform: 'rotate(14deg)' },
            '20%': { transform: 'rotate(-8deg)' },
            '30%': { transform: 'rotate(14deg)' },
            '40%': { transform: 'rotate(-4deg)' },
            '50%': { transform: 'rotate(10.0deg)' },
            '60%': { transform: 'rotate(0.0deg)' },
            '100%': { transform: 'rotate(0.0deg)' },
        },
        shine: {
            "100%": { left: "125%" },
          },      
      },
      animation: {
        fadeUp: "fadeUp 0.5s ease-in-out",
        fadeDown: "fadeDown 0.5s ease-in-out",
        waving: 'wave 2s linear infinite',
        shine: "shine 2s",
      },
    },
    screens: {
        'sm': '640px',
        'md': '768px',
        'lg': '1024px',
        'xl': '1280px',
        '2xl': '1536px',
        "3xl": "1920px",
        "ultrawide": "2560px",
    }
  },
  plugins: [
    require("@tailwindcss/aspect-ratio"),
    require('tailwind-scrollbar'),
    require("flowbite/plugin")
  ],
  variants: {
    scrollbar: ['dark']
  }
}
