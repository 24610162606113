import VideoThumbnail from "/componentry/Videos/Thumbnail";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import ReactiveLogo from './ReactiveLogo';

export default function SizeDependentCarousel({ dependents, breakpoint, slidesPerView }) {
    const { companies } = dependents;
    const breakpoints = {
        mobile: "grid md:hidden",
        md: "hidden md:grid lg:hidden",
        lg: "hidden lg:grid",
    }

    if (breakpoints[breakpoint] === undefined) throw new Error(`Invalid breakpoint: ${breakpoint}`);

    return (
        <Swiper
            modules={[Autoplay]}
            spaceBetween={2}
            slidesPerView={slidesPerView}
            centeredSlides
            autoplay={{
                disableOnInteraction: false,
                delay: 2500
            }}
            onSwiper={(swiper) => {
                swiper.autoplay.running = true;
            }}
            loop
            speed={1000}
            className={`${breakpoints[breakpoint]} grid-cols-12 px-16`}
        >
            {
                Object.values(companies).map((c, index) => {
                    const isIcon = typeof c.logo !== "string";
                    return <SwiperSlide key={index}>
                        <div className={`flex flex-col items-center justify-center`}>
                            <ReactiveLogo c={c} isIcon={isIcon} />
                        </div>
                    </SwiperSlide>
                })
            }
        </Swiper>
    )
};