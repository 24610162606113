import { FaCalendar, FaClock, FaCodeBranch, FaEye, FaPaintBrush, FaPenNib, FaPhotoVideo, FaTiktok, FaUserCircle, FaVideo, FaYoutube } from "react-icons/fa";
import { TbGauge } from "react-icons/tb";
import { HomeContext as DataContext } from "/componentry/Root/Shared/DataContexts.jsx";
import { fetchClients } from "/lib/helpers";
import { PagewideContext } from "/componentry/Root/Shared/PagewideContext.jsx";
import { server } from "/lib/publicEnv";
import { pickRandom } from "/lib/mathsExtension";
import Image from 'next/image';

import VideoCarousel from "/componentry/Home/Carousels/Videos/Core";
import ClientCarousel from "/componentry/Home/Carousels/Clients/Core";
import CompaniesCarousel from "/componentry/Home/Carousels/Companies/Core";

import { useEffect, useState, useContext } from "react";

import { Element as ScrollElement } from "react-scroll";

import TopStatistic from "/componentry/Home/TopStatistic.jsx";
import PriceEstimator from "/componentry/Home/PriceEstimator.jsx";

import Headline from "/componentry/Home/Headline.jsx";

import Section from "/componentry/Home/About/Section.jsx";
import Element from "../componentry/Home/Services/Element";

import AboutHeadline from "/componentry/Home/About/AboutHeadline.jsx";
import BottomBar from "/componentry/Root/Shared/BottomBar";

export default function Home() {
    const [loading, setLoading] = useState(true);
    const [dataContext, setDataContext] = useState({ });
    const { pageContext, setPageContext } = useContext(PagewideContext);
    const [props, setProps] = useState({
        totals: {
        videos: 0,
        clients: 0,
        views: 0,
        viewsExpanded: {
            views: 0,
            suffix: ""
        }
        },
        otherStatistics: {
        avgVideoDuration: 0,
        videosInPastYear: 0,
        videosInPastWeek: 0
        }
    });

    useEffect(() => {
        async function fetchTotals() {
            if (!loading) return;
            let data2 = await fetch(`${server}/api/getStatistics`).then(f => {
                return f.json();
            }).catch(e => {
                console.error(e);
                return {
                error: true
                };
            });
            
            var viewsNum = parseInt(data2.views.total.match(/\d+/)[0], 10);
            var pastYearNum = parseInt(data2.videos.videosInPastYear.match(/\d+/)[0], 10);

            setProps({
                totals: {
                    videos: data2.videos.total,
                    clients: data2.clients.total,
                    views: data2.views.total,
                    viewsExpanded: {
                        views: viewsNum,
                        suffix: data2.views.total.replace(viewsNum, "")
                    },
                        
                },
                otherStatistics: {
                    avgVideoDuration: data2.videos.averageDuration.formatted,
                    videosInPastYear: {
                        value: pastYearNum,
                        suffix: data2?.videos?.videosInPastYear?.replace(pastYearNum, "")
                    },
                    videosInPastWeek: (data2.videos.pastYearRaw / 12 / 4).toFixed(2)
                }
            });
        };

        async function fetchVideos() {
            const req = await fetch(`${server}/api/content/search?start=0&limit=32&favourite=true`).then(res => res.json()).catch(err => console.log(err));
            return pickRandom(req.hits?.hits.map(m => m._source), 16);
        };
        
        (async () => {
            if (loading) {
                const videoList = await fetchVideos();
                const bigClients = await fetchClients({ context: { state: pageContext, setState: setPageContext, loading }, bigClients: true });
                const allClients = await fetchClients({ context: { state: pageContext, setState: setPageContext, loading }, bigClients: false });
                await fetchTotals();
                setDataContext({ ...dataContext, loaded: true, videos: videoList, clients: allClients, bigClients });
                setLoading(false);
            };
        })();
    }, [loading]);

    let statistics = (
        <>
            <TopStatistic icon={FaCalendar} value={props.otherStatistics.videosInPastYear.value} valueSuffix={
                props.otherStatistics.videosInPastYear.suffix
            } statistic="videos/yr" forceCenterValue int noMobile />
            <TopStatistic icon={TbGauge} value={props.otherStatistics.videosInPastWeek} statistic="videos/wk" forceCenterValue int decimal noMobile />
            <TopStatistic icon={FaUserCircle} value={props.totals.clients} statistic="clients" forceCenterValue int />
            <TopStatistic icon={FaYoutube} value={props.totals.videos} statistic="videos" forceCenterValue int />
            <TopStatistic icon={FaEye} value={props.totals.viewsExpanded.views} statistic="views" valueSuffix={props.totals.viewsExpanded.suffix} forceCenterValue int smaller />
            <TopStatistic icon={FaClock} value={props.otherStatistics.avgVideoDuration} statistic="avg. duration" forceCenterValue noMobile />
        </>
    );
  return (
    <DataContext.Provider value={{ dataContext, setDataContext }}>
        <main className="flex flex-col">
            <div className="flex flex-col gap-y-8 pb-12 md:py-12">
                <div className="flex flex-row items-center justify-evenly gap-x-8">
                    <div className="hidden md:flex flex-row items-center gap-x-8">
                        <picture className="hidden md:block">
                            <Image
                                width="128"
                                height="128"
                                src="/kooleyy.png"
                                alt="Kooleyy's Avatar"
                                className="rounded-full w-24"
                                priority={true}
                            />
                        </picture>
                        <div>
                            <div>
                                <h1 className="hidden md:block text-4xl text-left">
                                    Hi there, I&lsquo;m Kooleyy. <b className="transition-all hidden md:inline-block animate-waving">👋🏻</b>
                                </h1>
                                <div className="hidden md:flex">
                                    <Headline />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden xl:grid grid-cols-3 gap-x-4 gap-y-4">
                        {statistics}
                    </div>
                </div>
                <div className="flex flex-col md:hidden px-12 gap-y-2">
                    <h1 className="text-4xl text-left">
                        Hi there. <b className="transition-all">👋🏻</b>
                    </h1>
                    <h2 className="text-xl font-medium text-left">
                        <Headline />
                    </h2>
                </div>
                <div className="flex xl:hidden px-12 flex-col gap-y-4">
                    <h3 className="text-2xl text-left">
                        Statistics
                    </h3>
                    <div className="grid xl:hidden grid-cols-3 gap-x-4 gap-y-4">
                        {statistics}
                    </div>
                </div>
                <div className="flex flex-col gap-y-0 md:gap-y-6">
                    <div className={`flex flex-col pl-12 md:px-12 gap-y-6 items-center`}>
                        <VideoCarousel />
                    </div>
                    <div className={`flex flex-col pl-12 md:px-12 gap-y-6 items-center`}>
                        <ClientCarousel />
                    </div>
                </div>
                <div className="flex-col gap-y-6 px-12 hidden">
                    <h1 className="text-3xl text-center">Price Estimator</h1>
                    <PriceEstimator />
                </div>
            </div>
            <div className="flex flex-col space-y-0" id="AboutContainer">
                <ScrollElement name="about" />
                <Section 
                    header={<>Work with a team of <br /> experienced professionals</>}
                    description="My content management team, Continuity, will provide all of the resources you need to develop your brand and grow your audience. Your ideas combined with our creative talent and experience will help you achieve the results you’ve dreamed of. "
                    iconSrc="/about/avatar_1.png"
                    alt="A graphic of Kooleyy shaking hands with a client"
                    alternate
                />
                <Section 
                    header="While you sit back and watch results"
                    description="Put your feet up while we focus on high quality, eye catching content that you can be proud of. We take the weight of content production off your shoulders so you can enjoy content creation without the stress."
                    iconSrc="/about/avatar_2.png"
                    alt="A graphic of Kooleyy laying back in a chair outside"
                />
                <Section 
                    header={<>
                        Stop wasting time searching for <br />
                        <AboutHeadline />
                    </>}
                    description="You won’t have to look for new team members anymore. We have you covered! We scale our business to fit your needs. You can have confidence, knowing that we are making sure all your needs are catered for."
                    iconSrc="/about/avatar_3.png"
                    alt="A graphic of Kooleyy working on his computer"
                    alternate
                />
            </div>
            <div className="flex flex-col gap-y-8 py-16">
                <h1 className="text-3xl text-center">Our Services</h1>
                <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 items-start px-12 md:px-24 lg:px-48 space-y-8 md:space-y-0 md:gap-x-8 gap-y-12">
                    <Element icon={FaVideo} title="Video Editing">
                        We focus on high average view duration (AVD) and retention through the use of pacing, music and effects to keep viewers hooked. We can easily adapt our editing styles to bring your content ideas into reality.
                    </Element>
                    <Element icon={FaTiktok} title="Short-Form Content">
                        We convert iconic moments from long-form videos into unique, original and engaging short-form content that’s up-to-date with current trends.
                    </Element>
                    <Element icon={FaPhotoVideo} title="Thumbnails">
                        We design eye-catching thumbnails that incorporate all the current trends to attract new viewers and encourage greater interaction from existing viewers!
                    </Element>
                    <Element icon={FaPenNib} title="Design and Branding">
                        We assist in building your online presence though social media branding and website design to showcase and accentuate your work while identifying and engaging your targeted audience.
                    </Element>
                    <Element icon={FaCodeBranch} title="Development">
                        We build personalized websites, Discord bots and Minecraft plugins/mods to enhance your viewer interaction and engagement and help create unique experiences in your content.
                    </Element>
                    <Element icon={FaPaintBrush} title="Art and Animation">
                        We supply creative and talented artists that will add a splash of colour to your creative needs.
                    </Element>
                </div>
            </div>
            <div className={`${loading ? "hidden" : "flex"} flex-col gap-y-12 items-center py-28 bg-gray-700`}>
                <h1 className="text-2xl px-4 md:px-0 md:text-3xl text-center">Worked proudly in collaboration with</h1>
                <CompaniesCarousel />
            </div>
            <ScrollElement name="faq" />
            <BottomBar />
        </main>
    </DataContext.Provider>
  );
};