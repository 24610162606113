import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import { server } from "/lib/publicEnv";
import { HomeContext as DataContext } from "/componentry/Root/Shared/DataContexts.jsx";
import { useTailwindBreakpoint } from '/lib/hooks/useTailwindBreakpoint';

import { useState, useEffect, useContext } from "react";

import Link from "next/link";
import SizeDependentCarousel from '/componentry/Home/Carousels/Videos/SizeDependentCarousel';

export default function CompanyCarouselCore({ props }) {
    const { dataContext } = useContext(DataContext);
    const { videos, clients, loaded } = dataContext;

    return (
        <div>
            <div>
                <div className="flex flex-row items-center justify-start gap-x-4 gap-y-4 pb-6">
                    <h1 className="text-2xl md:text-3xl text-left">Featured Videos</h1>
                    <Link href="/v?favourite=true" className="flex flex-row font-BreezeButton gap-x-2 text-xs leading-tight px-4 py-2 bg-cyan-200 hover:bg-cyan-300 active:shadow-lg transition duration-150 ease-in-out uppercase text-sp text-black rounded-full">
                        More
                    </Link>
                </div>
            </div>
            <SizeDependentCarousel dependents={{ props, videos, clients, loaded }} breakpoint="mobile" slidesPerView={1.25} />
            <SizeDependentCarousel dependents={{ props, videos, clients, loaded }} breakpoint="md" slidesPerView={2.5} />
            <SizeDependentCarousel dependents={{ props, videos, clients, loaded }} breakpoint="lg" slidesPerView={4} />
            <SizeDependentCarousel dependents={{ props, videos, clients, loaded }} breakpoint="xl" slidesPerView={5} />
        </div>
    );  
};