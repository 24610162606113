import Image from 'next/image';

import { blurImageURL } from "/lib/publicEnv.js";
import { FaCrown, FaYoutube } from "react-icons/fa";

export default function Thumbnail(props) {
    return (
        <>
            <div className="flex flex-col gap-y-2 justify-center items-center">
                <div className="relative w-auto rounded-2xl overflow-hidden mx-auto">
                    <a href={props.href} rel="noreferrer" target="_blank" className="relative w-auto overflow-hidden">
                        <div className="rounded-2xl bg-black opacity-0 hover:opacity-[0.15] absolute w-full h-full top-0 left-0 transition-all ease-in-out duration-200" />
                        <Image src={props.thumb} placeholder="blur" blurDataURL={blurImageURL.client} width="256" height="256" referrerPolicy="no-referrer" alt="Avatar" className={`object-cover rounded-xl w-full h-full ${props.className}`} priority />
                    </a>
                    {
                        !isNaN(props.subscribers) ? (
                            <span className="hidden sm:flex items-center flex-row gap-x-2 absolute w-fit mx-2 my-2 py-1 px-1 bottom-0 right-0 bg-gray-800 text-white text-xs text-center leading-4 rounded-md z-20"><FaYoutube /> {Intl.NumberFormat("en-US", { notation: "compact", maximumFractionDigits: 1 }).format(props.subscribers)}+</span>
                        ) : null
                    }
                </div>
                <a href={props.href} rel="noreferrer" target="_blank" className={`flex gap-x-2 font-BreezeHeader font-bold text-xl w-fit text-center ${props.me ? "text-yellow-400 hover:text-yellow-600" : "hover:text-gray-300"} transition-all ease-in-out duration-200 items-center text-sm min-w-2 max-w-4`}>{props.name.slice(0, 16)}{props.name.length > 16 ? ".." : ""} { props.me ? (
                    <>
                        <FaCrown className="w-5" />
                    </>
                ) : null}</a>
            </div>
        </>
    )
};