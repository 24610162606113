import Image from 'next/image';
export default function Thumbnail(props) {
    return (
        <>
            <div className="flex flex-col gap-y-2 justify-center items-center animate-pulse">
                <Image width="256" height="256" referrerPolicy="no-referrer" alt="Avatar" className={`object-cover rounded-xl w-full h-full ${props.className}`} src={"/unloaded_client.png"} unoptimized />
                <div className="flex flex-col text-center justify-center items-center gap-y-3 pt-1">
                    <div className="h-4 w-16 bg-gray-500 rounded-full" /> 
                    <div className="h-3 w-32 bg-gray-500 rounded-full" /> 
                </div>
            </div>
        </>
    )
};