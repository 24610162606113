import Image from 'next/image';
import Link from "next/link";
import { SwiperSlide, useSwiperSlide } from 'swiper/react';

export default function ReactiveLogo({ isIcon, c }) {
    const slide = useSwiperSlide();
    return (
        <>
            {
                (() => {
                    if (isIcon) {
                        if (slide.isActive) return <c.logo className="w-28 h-28 transition ease-in-out duration-250" />;
                        return <c.logo className="w-24 h-24 text-gray-400 transition ease-in-out duration-250" />
                    } else {
                        if (slide.isActive) return <Image src={`${c.logo}`} width={96} height={96} className="w-28 h-28 transition ease-in-out duration-250" />
                        return <Image src={`${c.logo}`} width={96} height={96} className="w-24 h-24 text-gray-400 transition ease-in-out duration-250" />
                    }
                })()
            }
        </>
    );
};