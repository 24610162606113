import VideoThumbnail from "/componentry/Videos/Thumbnail";
import LoadingThumbnail from "/componentry/Videos/LoadingThumbnail";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper';

export default function SizeDependentCarousel({ dependents, breakpoint, endBreakpoint, slidesPerView }) {
    const { loaded, videos, clients } = dependents;
    const breakpoints = {
        mobile: "grid md:hidden",
        md: "hidden md:grid lg:hidden",
        lg: "hidden lg:grid xl:hidden",
        xl: "hidden xl:grid"
    }

    if (loaded && breakpoints[breakpoint] === undefined) throw new Error(`Invalid breakpoint: ${breakpoint}`);

    return (
        <Swiper
            modules={[Navigation, Scrollbar]}
            spaceBetween={16}
            navigation={breakpoint === "mobile" ? false : true}
            scrollbar={breakpoint === "mobile" ? false : { draggable: true }}
            slidesPerView={slidesPerView || 5}
            className={`${breakpoints[breakpoint]} grid-cols-12 px-16`}
        >
            {
                (() => {
                    if (loaded) {
                        return (
                            <>
                                {
                                    videos.map((video, key) => {
                                        return (
                                            <SwiperSlide key={key}>
                                                <div className="mb-8 md:mb-12">
                                                    <VideoThumbnail timestamp={new Date(video.timestamp)} key={key} playlistId={video.playlistId} sort={video.sort} href={`https://youtube.com/watch?v=${video.id}`} thumb={`https://i3.ytimg.com/vi_webp/${video.id}/maxresdefault.webp`} title={video.title} author={video.channel.name} authorPhoto={clients.find(c => c.id === video.channel.id).thumbnail.high.url} authorHref={`https://youtube.com/channel/${video.channel.id}`} duration={video.duration} views={video.social.views} hideFavourite={true} authorId={video.channel.id} optimized={true} />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </>
                        );
                    } else {
                        const keys = [...Array(16).keys()];
                        return (
                            <>
                                {
                                    keys.map((key) => {
                                        return (
                                            <SwiperSlide key={key}>
                                                <div className="mb-12">
                                                    <LoadingThumbnail />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </>
                        );
                    };
                })()
            }
        </Swiper>
    )
};