import { useEffect, useState } from "react";
import TypingEffect from 'react-typing-effect';
import { Strings } from "/componentry/Home/Headline";

export default function Headline() {
    const [currentText, setCurrentText] = useState(0);
    
    useEffect(() => {
        let interval = setInterval(() => {
            if (currentText === Strings.length - 1) setCurrentText(0);
            else setCurrentText(currentText + 1);
        }, (1000 * 2.5));

        return () => clearInterval(interval);
    }, [currentText]);

    let aboutStrings = [];

    Object.keys(Strings).forEach(function(key, index) {
        aboutStrings.push(Strings[key].main);
    });
        
    return (
        <>
            <TypingEffect
                className="font-bold"
                speed="100"
                eraseSpeed="50"
                eraseDelay="1000"
                typingDelay="500"
                text={aboutStrings}
            />
        </>
    );
};