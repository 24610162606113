import CountUp from "react-countup";

export default function TopStatistic(props) {
    return (
        <div className={`flex-col sm:flex-row gap-x-4 gap-y-2 px-6 py-4 justify-evenly items-center rounded-xl bg-slate-700 w-full ${props.noMobile ? "hidden md:flex" : "flex"}`}>
            <div className="hidden md:block">
                <props.icon className="w-10 text-4xl" />
            </div>
            <div className="flex flex-col">
                    {
                        (props.int && parseInt(props.value) !== NaN) ? (
                            <h2 className={`text-2xl font-BreezeHeader font-bold text-center ${props.forceCenterValue ? null : "md:text-center"} transition-all ease-in-out duration-200 md:hover:text-[1.703125rem]`}>
                                {props.tilde && "~"} <CountUp delay={2} duration={1} end={props.value} decimals={props.decimal ? 2 : false}  />{props.valueSuffix ? props.valueSuffix : ""}
                            </h2>
                        ) : (
                            <h2 className={`text-2xl font-BreezeHeader font-bold text-center ${props.forceCenterValue ? null : "md:text-center"} transition-all ease-in-out duration-200 md:hover:text-[1.703125rem]`}>{props.value}</h2>
                        )
                    }
                <p className={`text-sm md:text-base font-BreezeAltHeader font-medium text-center ${props.forceCenterValue ? null : "md:text-left"}`}>
                    {props.statistic}
                </p>
            </div>
        </div>
    );
};