import * as fns from "duration-fns";
import { formatTime } from "/lib/mathsExtension.js";
import { playlistIds } from "/lib/publicEnv.js";

import Image from 'next/image';

export default function Thumbnail(props) {
    let videoType = "Kooleyy";
    if (props.playlistId === playlistIds.teamVideos) {
        videoType = "Team";
    };
    if (props.playlistId === playlistIds.bestVideos) {
        videoType = "Best";
    };

    const duration = formatTime(fns.toSeconds(props.duration));

    return (
        <div className="flex flex-col gap-y-3 animate-pulse">
            <Image src="/unloaded.png" width="348" height="196" className={`object-cover w-full h-full rounded-xl bg-gray-500`} style={{ backgroundPosition: "center" }} />
            <div className="flex flex-col gap-y-2">
                <div className="flex flex-row gap-x-2 items-start">
                    <Image width="64" height="64" referrerPolicy="no-referrer" className="rounded-xl w-12 h-12" src={"/unloaded.png"} unoptimized />
                    <span className="font-BreezeAltHeader font-medium text-sm flex flex-col gap-y-2">
                        <div className="w-48 h-4 sm:w-12 md:w-16 lg:w-20 2xl:w-48 bg-gray-500 rounded-full" />
                        <div className="flex flex-row gap-x-2 font-normal">
                            <div className="w-24 h-4 sm:w-8 md:w-12 lg:w-16 2xl:w-24 bg-gray-500 rounded-full" />
                        </div>
                    </span>
                </div>
            </div>
        </div>
    );
};