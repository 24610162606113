import ClientThumbnail from "/componentry/Home/Carousels/Clients/Elements/ClientThumbnail";
import LoadingThumbnail from "/componentry/Home/Carousels/Clients/Elements/LoadingThumbnail";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper';
export default function SizeDependentCarousel({ dependents, breakpoint, slidesPerView }) {
    const { loaded, clients } = dependents;
    const breakpoints = {
        mobile: "grid sm:hidden",
        sm: "hidden sm:grid md:hidden",
        md: "hidden md:grid lg:hidden",
        lg: "hidden lg:grid xl:hidden",
        xl: "hidden xl:grid 2xl:hidden",
        "2xl": "hidden 2xl:grid"
    }

    if (loaded && breakpoints[breakpoint] === undefined) throw new Error(`Invalid breakpoint: ${breakpoint}`);

    return (
        <Swiper
            modules={[Navigation, Scrollbar]}
            spaceBetween={24}
            navigation={breakpoint === "mobile" ? false : true}
            scrollbar={breakpoint === "mobile" ? false : { draggable: true }}
            slidesPerView={slidesPerView}
            className={`${breakpoints[breakpoint]} grid grid-cols-8 px-16`}
        >
        {
            (() => {
                if (loaded) {
                    const keys = [...Array(16).keys()];
                    return (
                        <>
                            {
                                keys.map((key) => {
                                    let client = clients[key];
                                    if (client.name.length >= 18) {
                                        client.name = client.name.substring(0, 12) + "..";
                                    } else if (client.id === "UCClK5D4DrAihWMeMaSA1rpA") {
                                        client.name = "Everbloom";
                                    };
                                    return (
                                        <SwiperSlide key={key}>
                                            <div className="mb-8 md:mb-12">
                                                <ClientThumbnail key={key} id={client.id} href={`https://youtube.com/channel/${client.id}`} thumb={client.thumbnail.high.url} name={client.name} subscribers={client.channelSocial.subscribers} />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </>
                    );
                } else {
                    const keys = [...Array(16).keys()];
                    return (
                        <>
                            {
                                keys.map((key) => {
                                    return (
                                        <SwiperSlide key={key}>
                                            <div className="mb-12">
                                                <LoadingThumbnail />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </>
                    );
                };
            })()
        }
    </Swiper>
    )
};